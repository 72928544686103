import axios from 'axios';
import qs from 'qs';
import { Toast } from 'vant';
import { creatSign } from './util';

axios.defaults.withCredentials = true;
const method = ['get', 'post', 'delete', 'put', 'head'];
const request = {};
for (let item of method) {
  request[item] = ({
    url,
    data,
    params,
    hideLoading = false,
    formate = true,
    message = '加载中...',
    contentType = 'application/json',
    removeEmpty = true,
    headers,
  }) => {
    return new Promise((resolve, reject) => {
      let loading;
      let sign = '';
      if (!hideLoading) {
        loading = Toast.loading({
          message,
          forbidClick: true,
        });
      }
      let config = {
        method: item,
        url,
        headers: {
          'Content-Type': contentType,
          'sig': sign,
          ...headers,
        },
        formate: formate,
        withCredentials: true,
        paramsSerializer: function (params) {
          return qs.stringify(params);
        },
      };
      if (data) {
        if (removeEmpty) {
          removeEmptyProp(data);
        }
        if (contentType === 'application/json') {
          config.data = data;
        } else {
          config.data = qs.stringify(data);
        }
      }
      if (params) {
        if (removeEmpty) {
          removeEmptyProp(params);
        }
        config.params = params;
      }
      if (item === 'get') {
        if (config.params) {
          Object.assign(config.params, {
            _rnd: Math.random(),
          });
        } else {
          config.params = {
            _rnd: Math.random(),
          };
        }
      }
      // 加签名
      sign = item === 'post' ? creatSign(config.data) : creatSign(config.params);
      config.headers.sig = sign;
      axios(config)
        .then(({ data }) => {
          if (loading) loading.clear();
          if (data.code !== '0') {
            reject(data);
          }
          config.formate ? resolve(data.data) : resolve(data);
        })
        .catch((err) => {
          if (loading) loading.clear();
          reject(err);
        });
    });
  };
}

function removeEmptyProp(data) {
  if (typeof data === 'object') {
    if (Array.isArray(data)) {
      for (let item of data) {
        removeEmptyProp(item);
      }
    } else {
      for (let key in data) {
        if (data[key] === null || data[key] === undefined || data[key] === '') {
          delete data[key];
        } else if (typeof data[key] === 'object') {
          if (Object.keys(data[key]).length === 0) {
            delete data[key];
          } else {
            removeEmptyProp(data[key]);
          }
        }
      }
    }
  }
}

export default request;
